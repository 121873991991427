import React, {useState} from 'react';
import './App.css';
import {Col, Row, Container, FormControl, Table} from 'react-bootstrap'
import _uniqBy from 'lodash.uniqby'
import PWAPrompt from 'react-ios-pwa-prompt'
const _codes = [

  {code: '03', label: 'Pahoinpitely, tappelu', header: true},
  {code: '031', label: 'Ampuminen'},
  {code: '032', label: 'Puukotus'},
  {code: '033', label: 'Potkiminen / hakkaaminen'},
  {code: '034', label: 'Pahoinpitely: tekotapa epäselvä'},
  {code: '06', label: 'Viranomainen uhattuna', header: true},
  {code: '066', label: 'Viranomainen uhattuna: hätäkutsu VIRVE -radiolla'},
  {code: '10', label: 'Ilmoitinlaiteilmoitus', header: true},
  {code: '103', label: 'Automaattinen palohälytys'},
  {code: '104', label: 'Ilmoitinlaitehälytys: säteilyhälytys'},
  {code: '105', label: 'Ilmoitinlaitehälytys: hissihälytys'},
  {code: '106', label: 'Ilmoitinlaitehälytys: laitevika'},
  {code: '107', label: 'Ilmoitinlaitehälytys: yhteysvika'},
  {code: '108', label: 'Ilmoitinlaitehälytys: huolto'},
  {code: '20', label: 'Tieliikenneonnettomuus', header: true},
  {code: '200', label: 'Tieliikenneonnettomuus: muu tai onnettomuuden uhka'},
  {code: '201', label: 'Tieliikenneonnettomuus: peltikolari, suistuminen'},
  {code: '202', label: 'Tieliikenneonnettomuus: pieni'},
  {code: '203', label: 'Tieliikenneonnettomuus: keskisuuri'},
  {code: '204', label: 'Tieliikenneonnettomuus: suuri'},
  {code: '205', label: 'Tieliikenneonnettomuus: eläin osallisena (ei henkilövahinkoja)'},
  {code: '206', label: 'Tieliikenneonnettomuus maan alla: pieni'},
  {code: '207', label: 'Tieliikenneonnettomuus maan alla: keskisuuri'},
  {code: '208', label: 'Tieliikenneonnettomuus maan alla: suuri'},
  {code: '21', label: 'Raideliikenneonnettomuus', header: true},
  {code: '210', label: 'Raideliikenneonnettomuus: muu'},
  {code: '211', label: 'Raideliikenneonnettomuus: peltikolari'},
  {code: '212', label: 'Raideliikenneonnettomuus: pieni'},
  {code: '213', label: 'Raideliikenneonnettomuus: keskisuuri'},
  {code: '214', label: 'Raideliikenneonnettomuus: suuri'},
  {code: '215', label: 'Raideliikenneonnettomuus: eläin osallisena'},
  {code: '216', label: 'Raideliikenneonnettomuus maan alla: pieni'},
  {code: '217', label: 'Raideliikenneonnettomuus maan alla: keskisuuri'},
  {code: '218', label: 'Raideliikenneonnettomuus maan alla: suuri'},
  {code: '22', label: 'Vesiliikenneonnettomuus', header: true},
  {code: '220', label: 'Vesiliikenneonnettomuus: Muu'},
  {code: '221', label: 'Vesiliikenneonnettomuus: pieni'},
  {code: '222', label: 'Vesiliikenneonnettomuus: keskisuuri'},
  {code: '223', label: 'Vesiliikenneonnettomuus: suuri'},
  {code: '23', label: 'Ilmaliikenneonnettomuus tai -vaara', header: true},
  {code: '231', label: 'Ilmaliikenneonnettomuus: pieni'},
  {code: '232', label: 'Ilmaliikenneonnettomuus: keskisuuri'},
  {code: '233', label: 'Ilmaliikenneonnettomuus: suuri'},
  {code: '234', label: 'Ilmaliikenneonnettomuusvaara: pieni'},
  {code: '235', label: 'Ilmaliikenneonnettomuusvaara: keskisuuri'},
  {code: '236', label: 'Ilmaliikenneonnettomuusvaara: suuri'},
  {code: '27', label: 'Maastoliikennetehtävä', header: true},
  {code: '270', label: 'Maastoliikennetehtävä: muu'},
  {code: '271', label: 'Maastoliikenneonnettomuus'},
  {code: '40', label: 'Rakennuspalo', header: true},
  {code: '401', label: 'Rakennuspalo: pieni'},
  {code: '402', label: 'Rakennuspalo: keskisuuri'},
  {code: '403', label: 'Rakennuspalo: suuri'},
  {code: '404', label: 'Rakennuspalo maan alla: pieni'},
  {code: '405', label: 'Rakennuspalo maan alla: keskisuuri'},
  {code: '406', label: 'Rakennuspalo maan alla: suuri'},
  {code: '41', label: 'Liikennevälinepalo', header: true},
  {code: '411', label: 'Liikennevälinepalo: pieni'},
  {code: '412', label: 'Liikennevälinepalo:keskisuuri'},
  {code: '413', label: 'Liikennevälinepalo:suuri'},
  {code: '42', label: 'Maastopalo', header: true},
  {code: '420', label: 'savuhavainto/tarkastustehtävä'},
  {code: '421', label: 'Maastopalo : pieni'},
  {code: '422', label: 'Maastopalo : keskisuuri'},
  {code: '423', label: 'Maastopalo : suuri'},
  {code: '424', label: 'Turvetuotantoaluepalo: pieni'},
  {code: '425', label: 'Turvetuotantoaluepalo: keskisuuri'},
  {code: '426', label: 'Turvetuotantoaluepalo: suuri'},
  {code: '43', label: 'Tulipalo, muu', header: true},
  {code: '431', label: 'Tulipalo muu : pieni'},
  {code: '432', label: 'Tulipalo muu : keskisuuri'},
  {code: '433', label: 'Tulipalo muu : suuri'},
  {code: '434', label: 'Tulipalo muu, maan alla: pieni'},
  {code: '435', label: 'Tulipalo muu, maan alla: keskisuuri'},
  {code: '436', label: 'Tulipalo muu, maan alla: suuri'},
  {code: '44', label: 'Räjähdys / sotruma', header: true},
  {code: '441', label: 'Räjähdys / sortuma: pieni'},
  {code: '442', label: 'Räjähdys / sortuma: keskisuuri'},
  {code: '443', label: 'Räjähdys / sortuma: suuri'},
  {code: '444', label: 'Räjähdys- / sortumavaara'},
  {code: '45', label: 'Vaarallisen aineen onnettomuus', header: true},
  {code: '451', label: 'Vaarallisen aineen onnettomuus: pieni'},
  {code: '452', label: 'Vaarallisen aineen onnettomuus: keskisuuri'},
  {code: '453', label: 'Vaarallisen aineen onnettomuus: suuri'},
  {code: '455', label: 'Vaarallisen aineen onnettomuus: vaara'},
  {code: '456', label: 'Ydinvoimalaitos: varautumistilanne'},
  {code: '457', label: 'Ydinvoimalaitos: laitoshätätilanne'},
  {code: '458', label: 'Ydinvoimalaitos: yleishätätilanne'},
  {code: '46', label: 'Vahingontorjunta', header: true},
  {code: '461', label: 'Vahingontorjunta: pieni'},
  {code: '462', label: 'Vahingontorjunta: keskisuuri'},
  {code: '463', label: 'Vahingontorjunta: suuri'},
  {code: '47', label: 'Öljyvahinko', header: true},
  {code: '471', label: 'Öljyvahinko / ympäristöonnettomuus: maalla, pieni'},
  {code: '472', label: 'Öljyvahinko / ympäristöonnettomuus: maalla, keskisuuri'},
  {code: '473', label: 'Öljyvahinko / ympäristöonnettomuus: maalla, suuri'},
  {code: '474', label: 'Öljyvahinko / ympäristöonnettomuus: vesistössä, pieni'},
  {code: '475', label: 'Öljyvahinko / ympäristöonnettomuus: vesistössä, keskisuuri'},
  {code: '476', label: 'Öljyvahinko / ympäristöonnettomuus: vesistössä, suuri'},
  {code: '477', label: 'Öljyvahinko / ympäristöonnettomuus: vaara'},
  {code: '48', label: 'Ihmisen pelastaminen', header: true},
  {code: '480', label: 'Ihmisen pelastaminen: Muu'},
  {code: '481', label: 'Ihmisen pelastaminen: Etsintä'},
  {code: '482', label: 'Ihmisen pelastaminen: Avunanto'},
  {code: '483', label: 'Ihmisen pelastaminen: Vedestä'},
  {code: '484', label: 'Ihmisen pelastaminen: Pintapelastus'},
  {code: '485', label: 'Ihmisen pelastaminen: Maastosta'},
  {code: '486', label: 'Ihmisen pelastaminen: Puristuksista'},
  {code: '487', label: 'Ihmisen pelastaminen: ylhäältä/Alhaalta'},
  {code: '49', label: 'Onnettomuustilanne, muu', header: true},
  {code: '490', label: 'Onnettomuustilanne maanpäällä (epäselvä tilanne, koodi tarkentuu)'},
  {code: '414', label: 'Liikennevälinepalo maan alla: pieni'},
  {code: '415', label: 'Liikennevälinepalo maan alla: keskisuuri'},
  {code: '416', label: 'Liikennevälinepalo maan alla: suuri'},
  {code: '55', label: 'Avunanto', header: true},
  {code: '550', label: 'Avunanto: muu'},
  {code: '551', label: 'Avunanto: virka-apu toiselle viranomaiselle'},
  {code: '552', label: 'Avunanto: tarkistus tms. tehtävä'},
  {code: '553', label: 'Avunanto: uhka- / varuillaolotehtävä'},
  /*{code: '554', label: 'Avunanto: Tarkistus- / varmistustehtävä'},*/
  {code: '58', label: 'Eläintehtävä', header: true},
  {code: '580', label: 'Eläintehtävä: muu'},
  {code: '581', label: 'Eläintehtävä: eläimen pelastaminen'},
  {code: '70', label: 'Peruselintoiminnan häiriö', header: true},
  {code: '700', label: 'Eloton'},
  {code: '701', label: 'Elvytys'},
  {code: '702', label: 'Tajuttomuus'},
  {code: '703', label: 'Hengitysvaikeus'},
  {code: '704', label: 'Rintakipu'},
  {code: '705', label: 'Rytmihäiriö'},
  {code: '706', label: 'Aivohalvaus'},
  {code: '71', label: 'Hapenpuute', header: true},
  {code: '711', label: 'Ilmatie-este'},
  {code: '713', label: 'Hirttäytyminen, Kuristuminen'},
  {code: '714', label: 'Hukkuminen'},
  {code: '74', label: 'Vamma (muu mekaaninen)', header: true},
  {code: '741', label: 'Putoaminen'},
  {code: '744', label: 'Haava'},
  {code: '745', label: 'Kaatuminen'},
  {code: '746', label: 'Isku'},
  {code: '747', label: 'Vamma: muu'},
  {code: '75', label: 'Onnettomuus (ei mekaaninen)', header: true},
  {code: '751', label: 'Kaasumyrkytys'},
  {code: '752', label: 'Myrkytys'},
  {code: '753', label: 'Sähköisku'},
  {code: '754', label: 'Palovamma'},
  {code: '755', label: 'Palovamma, lämpöhalvaus'},
  {code: '756', label: 'Paleltuminen, alilämpöisyys'},
  {code: '757', label: 'Onnettomuus, muu'},
  {code: '76', label: 'Verenvuoto (ilman vammaa)', header: true},
  {code: '761', label: 'Verenvuoto: suusta'},
  {code: '762', label: 'Verenvuoto: gynekologinen/urologinen'},
  {code: '763', label: 'Verenvuoto: korva/Nenä'},
  {code: '764', label: 'Säärihaava/Muu'},
  {code: '77', label: 'Sairaus (liittyy löydös)', header: true},
  {code: '770', label: 'Sairauskohtaus'},
  {code: '771', label: 'Sokeritasapainon häiriö'},
  {code: '772', label: 'Kouristelu'},
  {code: '773', label: 'Yliherkkyysreaktio'},
  {code: '774', label: 'Muu sairastuminen'},
  {code: '775', label: 'Oksentelu, ripuli'},
  {code: '78', label: 'Sairaus (ilmenee oireena)', header: true},
  {code: '781', label: 'Vatsakipu'},
  {code: '782', label: 'Pää-/ niskasärky'},
  {code: '783', label: 'Selkä-/ raaja-/ vartalokipu'},
  {code: '785', label: 'Mielenterveysongelma'},
  {code: '79', label: 'Sairaankuljetustehtävä', header: true},
  {code: '790', label: 'Hälytys puhelun aikana'},
  {code: '791', label: 'Synnytys'},
  {code: '792', label: 'Varallaolo, valmiussiirto'},
  {code: '793', label: 'Hoitolaitossiirto'},
  {code: '794', label: 'Muu sairaankuljetustehtävä / aikatilaustehtävä'},
  {code: '796', label: 'Monipotilastilanne, suuronnettomuus'},
  {code: 'H35', label: 'Valmius', header: true},
  {code: 'H351', label: 'Asemavalmius'},
  {code: 'H352', label: 'Valmiussiirto'},
  {code: 'X', label: 'Ei kuljetusta', header: true},
  {code: 'X-0', label: 'Tekninen este'},
  {code: 'X-1', label: 'Kuollut'},
  {code: 'X-2', label: 'Terveydentila määritetty, ohjattu poliisin suojaan'},
  {code: 'X-3', label: 'Pyydetty kohteeseen muuta apua'},
  {code: 'X-4', label: 'Muu kuljetus'},
  {code: 'X-5', label: 'Terveydentila määritetty, ei tarvetta ensihoitoon tai hoito-toimenpiteisiin '},
  {code: 'X-6', label: 'Potilas kieltäytyi'},
  {code: 'X-7', label: 'Potilasta ei löydy'},
  {code: 'X-8', label: 'Potilas hoidettu kohteessa'},
  {code: 'X-9', label: 'Tehtävä peruutettu'},
]

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);
function App() {
  const [search, setSearch] = useState('')
  const isSearch =  !(!search || search.length === 0)
  const filteredCodes = () => {
    if(!isSearch) return _codes;
    if(isSearch){
      const _search = search.toLowerCase()
      const codes = _codes.filter(c => c.header !== true && (c.code.toLowerCase().indexOf(_search) === 0 || c.label.toLowerCase().indexOf(_search) > -1))
      const reversedCodes = [..._codes].reverse()
      const copyOfCodes = [...codes]
      let addedCount = 0
      copyOfCodes.forEach((c,i) => {
        const idx = reversedCodes.findIndex(cc => cc.code === c.code)
        if(idx > -1){
          const header = reversedCodes.slice(idx).find(cc => cc.header === true)
          codes.splice(i + addedCount, 0, header);
          addedCount++
        }
      })
      return _uniqBy(codes,'code')
    }
  }
  return (
    <div className="App">
      <Container>
        <Row>
          <Col md={12}>
            <div className={'text-center pt-3'}>
              <h1>Tehtäväluokat</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormControl value={search} onChange={e => setSearch(e.target.value)} placeholder={'Hae esim. 402 tai Rakennuspalo'} style={{marginTop: '25px'}} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Table hover striped size={'sm'}>
              <tbody>
              {filteredCodes().map((c,i) => {
                return(
                <tr key={i} className={c.header ? 'code-header bg-warning' : ''}>
                  <td style={{ whiteSpace: 'nowrap' }}>{c.code}</td>
                  <td>{c.label}</td>
                </tr>
                )
              })}

              </tbody>
            </Table>
          </Col>
        </Row>

        {!isSearch && <Row>
          <Col md={12}>
            <br /><br />
            <div style={{fontSize: '0.8em'}}>
            <p>Sivu on tarkoitettu lähinnä palo- ja ensihoitohenkilöstölle nopeaan tehtäväluokkien tunnistamiseen tehtävän tullessa.<br />
              Hakemiasi tehtäväluokkia ei lähetetä laitteesi ulkopuolelle eikä niitä tallenneta mihinkään.<br />
            Tätä sivua on mahdollisuus käyttää myös PWA-sovelluksena iOS- ja Android-laitteilla.
              </p>
            </div>
          </Col>
        </Row>}
      </Container>
      {iOSSafari && <PWAPrompt timesToShow={1} delay={5000}
                 copyTitle='Lisää Koti-valikkoon'
                 copyBody={'Tämä sivusto toimii kuin sovellus. Lisää sivu Koti-ruudullesi käyttääksesi sitä nopeasti Koti-valikosta jopa offline-tilassa!'}
                 copyShareButtonLabel={'Paina "Jaa"-painiketta'}
                 copyAddHomeButtonLabel={'Paina "Lisää Koti-valikkoon"'}
                 copyClosePrompt={'Peruuta'}

      />}
    </div>
  );
}

export default App;





